import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

// noinspection CssUnresolvedCustomProperty
@Component({
  selector: 'mri-datalist-item-data',
  template: `
    <span class="mri-datalist__item-data mri-datalist__item-data--1"
      ><span class="data-1-text">{{ item1 }}</span></span
    >
    <span class="mri-datalist__item-data mri-datalist__item-data--2"
      ><span class="data-2-text">{{ item2 }}</span></span
    >
  `,
  styles: [
    `
      .data-1-text {
        color: var(--mri-color-text-datalist-item-data-1, inherit);
      }
      .data-2-text {
        color: var(--mri-color-text-datalist-item-data-2, inherit);
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatalistItemDataComponent {
  @Input() item1: string | undefined;
  @Input() item2: string | undefined;

  @HostBinding('class.mri-datalist__item-data-area') readonly hostClass = true;
  @HostBinding('style.display') get shown() {
    return this.item1 === undefined && this.item2 === undefined ? 'none' : undefined;
  }
}
