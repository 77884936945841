import { Directive, HostBinding, Optional } from '@angular/core';
import { timer } from 'rxjs';
import { DatalistComponent } from './datalist.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'mri-avatar' })
export class DatalistItemIconDirective {
  @HostBinding('class.mri-datalist__item-icon') get isDatalistIcon() {
    return this.parentDatalist != null;
  }
  constructor(@Optional() private parentDatalist: DatalistComponent) {
    if (!parentDatalist || parentDatalist.icon != null) return;

    this.parentDatalist.icon = true;
    timer(0).subscribe(() => this.parentDatalist.cd.markForCheck());
  }
}
